

const RadioSearchDb = () => import("@/components/account/RadioSearchDb.vue")
const AccountDetailsDialog = () => import("@/components/dialogs/AccountDetailsDialog.vue")
const Results = () => import("@/components/account/Results.vue")
import Vue from "vue"
import { AccountItem, GetAccountDetails } from "@/types/responses"  
import { TableMode } from "@/types/enums"
export default Vue.extend({
	name: "AccountOverview",
	components: {
		RadioSearchDb,
		AccountDetailsDialog,
		Results
	},

	data(): {
		search: string
		searching: boolean
		loading: boolean
		tableMode: TableMode
		details: boolean
		
	} {
		return {
			search: "",
			searching: true,
			loading: false,
			details: false,
			tableMode: {remove: false, view: true}
			}
	},
	methods: {
		clearSearch(): void {
			this.search = ""
		},
		refresh(): void {
			
			this.details = false
		},
		openDetailsDialog(account: AccountItem): void{
			//console.log("EMITTANCE RECEIVED: " + account.userId)
			this.details = true
				
			this.$vStore.dispatch("context/setSelectedAccount", account)

		},
	},

})
